/**
  * .wm-progress 1.0.0
  * Fortschrittsanzeige in Formularen
  * Im Handbuch: /pattern-library/patterns/formulare/#darstellung_fortschrittsanzeige
*/

.wm-progress {
  &__text {
    .wm-progress__step:nth-last-child(-n+5):first-child & {
      @include wm-progress-left-center;
    }

    .wm-progress__step:nth-last-child(-n+5):first-child &,
    .wm-progress__step:nth-last-child(-n+5):first-child ~ .wm-progress__step & {
      display: block;
    }

    @include mq(xl) {
      .wm-progress__step:nth-last-child(-n+5):first-child &,
      .wm-progress__step:nth-last-child(-n+6):first-child & {
        @include wm-progress-left-center;
      }

      .wm-progress__step:nth-last-child(-n+6):first-child &,
      .wm-progress__step:nth-last-child(-n+6):first-child ~ .wm-progress__step & {
        display: block;
      }
    }

    @include mq(wide) {
      width: 15rem;
      display: block;
      hyphens: auto;
      text-align: center;

      .wm-progress__step:first-child & {
        left: 50%;
        transform: translateX(-50%) translateY(-100%);
      }

      .wm-progress__step:last-child & {
        @include wm-progress-right-center;
      }
    }
  }
}
