/**
  * .wm-nav-inpage 1.0.0
  * In Page Navigation
  * Im Handbuch: /pattern-library/patterns/in-page-navigation/
*/

.wm-nav-inpage {
  .wm-site--scrolled & {
    --offset: calc(#{$wm-site-header-height} + #{$wm-nav-main-ms-height});
  }

  &__items {
    &--toggle {
      padding: $wm-spacing-large;
    }

    @include mq('content-full') {
      display: block;

      &--toggle {
        padding: 0;
        box-shadow: $wm-shadow-desktop;
      }
    }

    @include mq('wide') {
      padding: 2.5rem 0;
    }
  }

  &__link {
    @include mq('content-full') {
      &:hover,
      &--active {
        background-color: wm-brand-color("nebelgrau-light");
      }

      &:focus-visible {
        background-color: wm-brand-color("nebelgrau-light");
      }
    }

    @include mq(wide) {
      padding-right: $wm-spacing-xlarge - $wm-site-padding;
      padding-left: $wm-spacing-xlarge - $wm-site-padding;
    }
  }

  &__wrapper {
    @include mq('content-full') {
      display: grid;
      grid-template-columns: minmax(#{$wm-spacing-xlarge}, 1fr) minmax(auto, 110rem) minmax(#{$wm-spacing-xlarge}, 1fr);
    }
  }

  &__content {
    @include mq('content-full') {
      min-width: 0;
      grid-row: 1;
      grid-column: 1 / -1;
    }
  }

  &__toggle {
    @include mq('content-full') {
      display: none;
    }
  }

  @include mq('content-full') {
    @include sticky;

    max-width: 28rem;
    max-height: $wm-in-page-nav-height-large;
    margin-left: auto;
    background-color: wm-brand-color("weiss");
    grid-row: 1;
    grid-column: -2 / -3;

    .wm-site--scrolled & {
      --offset: calc(#{$wm-site-header-height} + #{$wm-nav-main-ms-height} + #{$wm-spacing-large});
    }

    .wien & {
      top: $wm-site-header-height + $wm-spacing-large;
    }
  }

  @include mq(wide) {
    max-width: 32.5rem;
  }
}
