/**
  * .wm-list 1.0.0
  * Liststyles
  * Im Handbuch: /pattern-library/patterns/liste/
*/

.wm-list {
  &--2col {
    column-count: 2;
    column-gap: $wm-grid-gap * 9; // list padding: 2 + wm-row--gap7 (_row_large.scss)
  }

  &--pipe-lg {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > * + * {
      margin-left: $wm-spacing-small;

      &::before {
        content: "|";
        padding-right: $wm-spacing-xsmall;
      }

      &:first-child {
        margin-left: 0;

        &::before {
          display: none;
        }
      }
    }

    [dir="rtl"] & > * + * {
      margin-right: $wm-spacing-small;
      margin-left: 0;

      &::before {
        padding-right: 0;
        padding-left: $wm-spacing-xsmall;
      }

      &:first-child {
        margin-right: 0;
      }
    }
  }
}
