/**
  * .wm-stage 1.0.0
  * „Schaufenster” am Anfang der Seite
  * Im Handbuch: /pattern-library/patterns/stage/
*/

.wm-stage {
  &__heading {
    font-size: $wm-f-h1-size-large;
  }

  &__text--color,
  &__text--media {
    padding: ($wm-spacing-large - $wm-spacing-xxsmall) $wm-spacing-large $wm-spacing-large;

    // Readd padding when inside Carousel
    .tns-item & {
      padding-bottom: $wm-spacing-large;
    }
  }

  &__media {
    &--desktop-only {
      display: grid;
    }
  }
}
