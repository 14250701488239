/**
  * .wm-nav-wrapper 1.0.0
  * Navigation von Microsites und von wien.gv.at
  * Im Handbuch: /pattern-library/patterns/menue/
*/

.wm-nav-wrapper {
  .wm-js &--h {
    @include mq(xl) {
      // Make fixed elements relative to wrapper
      transform: rotate(0);
    }
  }

  &--init {
    height: $wm-nav-main-ms-height;
    overflow: hidden;
  }

  .wm-js &__inner--h {
    @include mq(xl) {
      display: -ms-grid;
      -ms-grid-columns: 100%;
      display: grid; /* stylelint-disable-line */
      grid-template-columns: 100%;
    }
  }
}

.wm-nav-main {
  .wm-js .wm-nav-wrapper--h &--abendstimmung {
    @include mq(xl) {
      --nav_link_active: #{wm-brand-color("fastschwarz")};
    }
  }

  .wm-js &--h {
    @include mq(xl) {
      width: 100%;
      max-width: 100%;
      overflow: visible;
      -ms-grid-row: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      grid-column: 1;
      font-size: 1.7rem;
    }
  }

  &__toggle {
    .wm-js .wm-nav-main--h & {
      @include mq(xl) {
        display: none;
      }
    }
  }

  &__header {
    [data-nav-unlock] & {
      background-color: var(--nav_background);
    }

    .wien [data-nav-unlock] & {
      min-height: $wm-site-header-height-large;
      padding-left: $wm-spacing-xlarge;
      top: -2rem;
      right: 2rem;
    }

    .wien .wm-site--scrolled & {
      min-height: $wm-site-header-height;
      top: 0;
    }

    .wm-js .wm-nav-main--h & {
      @include mq(xl) {
        display: none;
      }
    }
  }

  &__inner {
    top: $wm-site-header-height-large;

    .wm-site--scrolled & {
      top: $wm-site-header-height;
    }

    [data-nav-active] & {
      height: calc(100vh - #{$wm-site-header-height-large});
    }

    .wm-site--scrolled[data-nav-active] & {
      height: calc(100vh - #{$wm-site-header-height});
    }

    .wien [data-nav-active] &,
    .wien .wm-site--scrolled[data-nav-active] & {
      height: 100vh;
    }

    .wm-js .wm-nav-main--h & {
      @include mq(xl) {
        height: auto;
        overflow: visible;
        background: none;
        opacity: 1;
        position: static;
      }
    }
  }

  &__content {
    .wm-js .wm-nav-main--h & {
      @include mq(xl) {
        padding: 0;
        visibility: visible;
        transition: none;
        transform: none;
      }
    }
  }

  // z-index: 0; important for IE stacking order
  &__backdrop {
    width: 100%;
    overflow: hidden;
    display: none;
    position: absolute;
    top: 5.6rem;
    left: 0;
    z-index: 0;
    pointer-events: none;

    &-inner {
      height: 100%;
      background: $nav_fallback_background;
      background: var(--nav_background_full);
      transition: transform 0.3s;
      transform: translateY(-100%);
    }
  }

  &__subnav-icon {
    @include mq(xl) {
      .wm-js .wm-nav-main--h & {
        display: none;
      }

      .wm-js .wm-nav-main--h [data-click] & {
        display: block;
      }
    }
  }

  &__actions {
    margin-right: 0;
    background-color: $nav_fallback_background_light;
    background-color: var(--nav_background);
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row-align: end;
    -ms-grid-column-align: end;
    grid-column: auto / 2;
    justify-self: end;
    transition: opacity 0.3s;

    &::before {
      width: 10rem;
      height: 100%;
      background:
        linear-gradient(
          90deg,
          rgba($nav_fallback_background-light, 0.1) 0%,
          rgba($nav_fallback_background-light, 0.5) 25%,
          $nav_fallback_background-light 54%
        );
      background:
        linear-gradient(
          90deg,
          var(--nav_background_gradient1) 0%,
          var(--nav_background_gradient2) 25%,
          var(--nav_background) 54%
        );
      content: "";
      display: block;
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 100%;

      [dir="rtl"] & {
        background:
          linear-gradient(
            -90deg,
            rgba($nav_fallback_background-light, 0.1) 0%,
            rgba($nav_fallback_background-light, 0.5) 25%,
            $nav_fallback_background-light 54%
          );
        background:
          linear-gradient(
            -90deg,
            var(--nav_background_gradient1) 0%,
            var(--nav_background_gradient2) 25%,
            var(--nav_background) 54%
          );
        right: auto;
        left: 100%;
      }
    }
  }

  &__lists {
    z-index: 2;

    .wien & {
      padding-top: $wm-site-header-height-large;
    }

    .wien .wm-site--scrolled & {
      padding-top: $wm-site-header-height;
    }

    .wm-js .wm-nav-main--h & {
      @include mq(xl) {
        max-width: 100%;
        padding: 0;
        min-height: auto;
      }
    }

    & > * + * {
      &::before {
        margin-right: 5rem;
        margin-left: 5rem;
      }
    }
  }

  &__list {
    &--padding {
      padding-right: 5rem;
      padding-left: 5rem;

      &::before {
        margin-right: 0;
        margin-left: 0;
      }
    }

    .wm-js .wm-nav-main--h &:not(&--1) {
      @include mq(xl) {
        height: auto;
      }
    }

    .wm-js .wm-nav-main--h &--1 {
      opacity: 1;

      @include mq(xl) {
        display: flex;
        text-transform: none;

        & > * + * {
          margin-left: 5rem;
        }
      }
    }

    .wm-js[dir="rtl"] .wm-nav-main--h &--1 {
      @include mq(xl) {
        & > * + * {
          margin-right: 5rem;
          margin-left: 0;
        }
      }
    }

    .wm-js .wm-nav-main--d &--1 {
      & > * {
        flex-grow: 1;
      }

      & > * + * {
        margin-left: 0;
      }
    }

    .wm-js .wm-nav-main--h &--2 {
      @include mq(xl) {
        width: 100%;
        background: none;
        position: absolute;
        top: 100%;
        left: 0;

        & > :first-child {
          padding-top: 0.5rem;
        }

        & > :last-child {
          padding-bottom: 0.5rem;
        }
      }
    }

    .wm-js .wm-nav-main--h &--3 {
      @include mq(xl) {
        height: auto !important;
        margin-bottom: 1rem;
        background: none;
        display: block !important;
        visibility: visible !important;
        opacity: 1 !important;
      }
    }

    .wm-js .wm-nav-main--h &--is-visible::before {
      @include mq(xl) {
        transform: translateY(5.6rem);
      }
    }

    @include mq(xl) {
      .wm-nav-main:not(.wm-nav-main--h) & {
        padding-left: 0 !important;
      }
    }
  }

  &__link {
    padding-right: 5rem;
    padding-left: 5rem;

    .wm-nav-main__list--2 & {
      padding-left: 5rem + 1rem;
    }

    .wm-nav-main__list--3 & {
      padding-left: 5rem + 2rem;
    }

    .wm-js .wm-nav-main--h & {
      @include mq(xl) {
        height: 5.6rem;
        padding-right: 0;
        padding-left: 0;

        @include state(":focus") {
          background: none;
        }
      }
    }

    .wm-js .wm-nav-main--h .wm-nav-main__list--1 > * > & {
      @include mq(xl) {
        &[data-active] {
          background: none;
          color: var(--nav_link);
          text-decoration: none;

          & > span {
            box-shadow: 0 4px 0 0 wm-brand-color("fastschwarz");
          }
        }

        &--active,
        &[aria-current="page"] {
          background: none;
          color: var(--nav_link);

          & > span {
            box-shadow: 0 4px 0 0 var(--nav_background_full);
          }

          @include state(":linkvisited") {
            text-decoration: none;
          }
        }

        @include state(":hover") {
          color: var(--nav_link);
          text-decoration: none;

          & > span {
            box-shadow: 0 4px 0 0 wm-brand-color("fastschwarz");
          }
        }

        @include state(":focus") {
          color: var(--nav_link);
          text-decoration: none;

          & > span {
            box-shadow: 0 4px 0 0 wm-brand-color("fastschwarz");
          }
        }
      }
    }

    .wm-js .wm-nav-main--h .wm-nav-main__list--2 & {
      @include mq(xl) {
        @include wm-font-weight("bold");

        height: auto;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        @include state(":linkvisited") {
          color: var(--nav_link_child);
        }
      }
    }

    .wm-js .wm-nav-main--h .wm-nav-main__list--3 & {
      @include mq(xl) {
        padding: 0;
      }
    }

    .wm-js .wm-nav-main--h .wm-nav-main__list--2 & {
      @include mq(xl) {
        @include state(":linkvisited") {
          color: var(--nav_link_child);
        }
      }
    }

    @include state(":focus") {
      text-decoration: underline;
    }
  }

  .wm-js .wm-nav-main--h &__backdrop--is-visible {
    display: block;
  }

  .wm-js .wm-nav-main--h .wm-nav-main__list--2 &__sublink {
    @include mq(xl) {
      @include wm-font-weight("extrabold");

      padding-bottom: 0;
    }
  }

  &__search {
    &-btn {
      flex-direction: row-reverse;

      span {
        @include wm-visually-visible;

        margin-right: 0.5rem;
        position: static;

        .wm-jsm .wm-site-search--active & {
          display: none;
        }
      }
    }
  }

  &__login-content {
    transform: translateY(2.8rem);
  }

  @include mq(xl) {
    &--overlap:focus-within + *:not(.wm-nav-main__actions--full),
    &--overlap:hover + *:not(.wm-nav-main__actions--full) {
      opacity: 0;
      pointer-events: none;
    }
  }
}
