/**
  * .wm-quicklinks 1.0.0
  * Linkliste mit Pfeilen
  * Im Handbuch: /pattern-library/patterns/quicklinks/
*/

.wm-quicklinks {
  &__items {
    &--3col {
      @include mq(xl) {
        column-count: 3;
        column-gap: $wm-grid-gap;
      }
    }
  }
}
