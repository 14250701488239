/**
  * .wm-modal 1.0.0
  * Lightbox
  * Im Handbuch: /pattern-library/patterns/lightbox/
*/

.wm-modal {
  &__wrapper {
    @include mq("xl-up") {
      max-width: 1070px;
      max-height: 602px;
    }
  }

  &__close {
    @include mq("xl-up") {
      top: $wm-spacing-small * -1;
      right: $wm-spacing-small;
      transform: translateY(-100%);
    }

    @include mq(1070px) {
      right: 0;
    }
  }
}

.wm-modal-content {
  $thumb-size: 5.4rem;

  &__item {
    &--loaded {
      height: 100%;
      padding-bottom: 0;
    }

    &--page {
      background: wm-brand-color('weiss');
    }

    &--image {
      @include mq("content-full") {
        &.wm-modal-content__item--loaded {
          background: wm-brand-color('nebelgrau-light');
        }
        img {
            min-height: 100%;
            min-width: 100%;
            object-fit: cover;
        }
      }

    }
  }

  &__page {
    height: 100%;

    @include mq("xl") {
      padding: $wm-spacing-small;
      top: 0;
    }
  }

  &__html {
    height: 100%;

    @include mq("xl") {
      padding: $wm-spacing-medium;
      top: 0;
    }
  }

  &__control {
    @include mq("content-full") {
      opacity: 0.7;

      &[rel="prev"] {
        transform: translate(-50%, -50%);
      }

      &[rel="next"] {
        transform: translate(50%, -50%);
      }
    }
  }

  &__thumbs {
    @include mq("xl-up") {
      width: calc(100% - #{$thumb-size} - #{$wm-spacing-xsmall});
      position: absolute;
      top: -1.5rem;
      bottom: auto;
      transform: translateY(-100%);
    }
  }

  &__thumb {
    @include mq("xl-up") {
      height: $thumb-size;
      flex-basis: $thumb-size;
    }
  }

  @include mq("xl-up") {
    img {
      max-height: 100%;
    }

    &__info {
      display: none;
    }
  }
}
