/**
  * .wm-section 1.0.0
  * Grobe Inhaltsblöcke auf Seiten
*/

.wm-section {
  margin-bottom: $wm-spacing-xlarge;

  &--highlight {
    padding-top: $wm-spacing-large;
    padding-bottom: $wm-spacing-large;

    & + & {
      margin-top: $wm-spacing-xlarge * -1;
    }

    @include mq(xl) {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }

    @include mq(wide) {
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
    }
  }

  &--reduced {
    margin-bottom: $wm-spacing-large;
  }

  &--clean {
    margin-bottom: 0;
  }

  &__content {
    &--carousel {
      // 1rem = extra spacing for dropshadows of cards
      max-width: $wm-site-max-width-large + 1rem;
    }

    @include mq(xl) {
      & > *:not(h2) + .wm-row,
      & > *:not(h2) + .wm-block {
        margin-top: $wm-spacing-large;
      }

      & > *:not(h2) + .wm-block--large {
        margin-top: $wm-spacing-xlarge;
      }

      & > h2 + * {
        margin-top: $wm-spacing-medium;
      }
    }
    &--bleed {
      padding: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include mq(xl) {
    &--reduced {
      margin-bottom: $wm-spacing-xlarge;
    }

    &--fixed {
      margin-bottom: 0;
    }

    &--intro {
      margin-bottom: $wm-spacing-xxsmall;
    }

    &--large {
      margin-bottom: $wm-spacing-xxlarge;
    }

    &--clean {
      margin-bottom: 0;
    }
  }

  @include mq(wide) {
    margin-bottom: $wm-spacing-xxlarge;

    &--highlight + &--highlight {
      margin-top: $wm-spacing-xxlarge * -1;
    }

    &--fixed {
      margin-bottom: 0;
    }

    &--reduced {
      margin-bottom: $wm-spacing-xlarge;
    }

    &--clean {
      margin-bottom: 0;
    }
  }

  @include mq(content-full-slider) {
    &--intro {
      margin-top: $wm-spacing-xlarge;
      padding-left: 4.5rem;
    }
  }
}
