/**
  * .wm-card 1.0.0
  * Darstellung von Inhalten in Cards
  * Im Handbuch: /pattern-library/patterns/cards/
*/

.wm-card {
  $card-spacing: $wm-spacing-large;

  padding: $wm-spacing-medium 0;
  grid-template-columns: $card-spacing 1fr $card-spacing;
  box-shadow: $wm-card-shadow-large;

  & > * + * {
    margin-top: $wm-spacing-medium;
  }

  &--s {
    padding: $wm-spacing-small 0;
    grid-template-columns: $wm-spacing-small 1fr $wm-spacing-small;

    & > * + * {
      margin-top: $wm-spacing-small;
    }
  }

  &--expose {
    padding: 0;
    grid-template-rows: $card-spacing 1fr $card-spacing;
    grid-template-columns: $card-spacing 1fr $wm-spacing-medium 1fr $card-spacing;
    gap: $wm-spacing-medium;

    & > :first-child {
      grid-row: 2;
      grid-column: 2;
    }

    & > :last-child {
      aspect-ratio: auto;
      grid-column: -3 / -1;
    }
  }

  &--expose-reverse {
    padding: 0;
    grid-template-rows: $card-spacing 1fr $card-spacing;
    grid-template-columns: $card-spacing 1fr $wm-spacing-medium 1fr $card-spacing;
    gap: $wm-spacing-medium;

    & > :first-child {
      grid-row: 2;
      grid-column: -3;
    }

    & > :last-child {
      aspect-ratio: auto;
      grid-column: 1 / 3;
    }
  }

  &__media {
    margin-bottom: $wm-spacing-medium;

    @supports (display: grid) {
      margin-top: $wm-spacing-medium * -1;
    }

    &--expose {
      margin: 0;
      grid-row: 1 / -1;
      flex-grow: 1;

      img:not([data-src]) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__badge {
    margin: 0 0 1rem;
  }
}
