/**
  * .wm-site-search 1.0.0
  * Suche im Header
  * Im Handbuch: /pattern-library/patterns/suchfeld/
*/

.wm-site-search {
  &__content {
    margin-left: $wm-spacing-xlarge;

    &--inline {
      margin: 0;
    }
  }
}
