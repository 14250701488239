/**
  * .wm-site-header 1.0.0
  * Header in Microsites und auf wien.gv.at
  * Im Handbuch: /pattern-library/patterns/header/
*/

.wm-site-header {
  height: $wm-site-header-height-large + $wm-nav-main-ms-height;
  margin-bottom: $wm-spacing-xlarge;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  align-items: flex-end;
  transition: transform $wm-anim-header;

  // Andere Höhe auf wien.gv.at Hauptseite
  .wien & {
    height: $wm-site-header-height-large;
    flex-wrap: nowrap;
  }

  // Standardmargin entfernen, wenn .wien Klasse vorhanden
  .wien:not(.wm-has-no-stage) {
    @include mq(xl) {
      margin-bottom: 0;
    }
  }

  // Standardmargin entfernen, wenn no-margin Modifier vorhanden
  &--no-margin {
    margin-bottom: 0;
  }

  // Container, der den Inhalt trägt.
  &__inner {
    transform: translateY(-2rem);

    .wm-jsm & {
      transition: transform $wm-anim-header;
    }

    & > * + * {
      margin-left: $wm-spacing-large;

      @include mq('xl') {
        margin-left: $wm-spacing-xlarge;
      }
    }

    [dir="rtl"] & > * + * {
      margin-right: $wm-spacing-large;
      margin-left: 0;

      @include mq('xl') {
        margin-right: $wm-spacing-xlarge;
        margin-left: 0;
      }
    }

    // Wenn Navigation offen
    @include inside('[data-nav-active]') {
      position: relative;
      top: -2rem;
      transform: none;
    }

    // Header soll nicht animieren wenn offen
    @include inside('.wm-jsm [data-nav-notransition]') {
      transition: none;
    }

    // Wenn Seite gescrolled
    @include inside('.wm-site--scrolled') {
      transform: none;
    }

    // Wenn Seite gescrolled und Navigation offen
    @include inside('.wm-site--scrolled[data-nav-active]') {
      top: 0;
    }
  }

  // Titel der Website bei Microsites
  &__title {
    font-size: $wm-f-h1-size-large;
    transition: transform $wm-anim-header;
    transform-origin: center left;

    @supports (scroll-margin-top: 0) {
      @include inside(".wm-site--scrolled") {
        transform: scale(0.7145);
      }
    }

    [dir="rtl"] & {
      transform-origin: center right;
    }
  }

  // Header soll nicht animieren wenn offen
  @include inside('[data-nav-notransition]') {
    transition: none;
  }

  // Wenn Seite gescrolled
  @include inside('.wm-site--scrolled') {
    transform: translateY(-4rem);
  }

  // Wenn Seite gescrolled und Navigation offen
  @include inside('.wm-site--scrolled[data-nav-active]') {
    top: -4rem;
    transition: none;
    transform: none;
  }
}
