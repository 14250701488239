/**
  * .wm-row 1.0.0
  * Wrapper für ein- oder mehrspaltige Reihen
*/

.wm-row {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: $wm-grid-gap * 3;

  & > * {
    min-width: 0;
    // Standardmäßig 50% Breite
    flex: 1 1 0%; // https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored

    // Wenn nur ein Element vorhanden, 71rem Maximalbreite
    &:only-child {
      flex: 0 1 $wm-content-max-width;
    }
  }
}
