/**
  * .wm-has-bot 1.0.0
  * Anpassungen für Seiten mit Chatbot
  * Im Handbuch: /pattern-library/patterns/chatbot/
*/

.wm-has-bot .wm-site-footer {
  margin-bottom: $wienbot-footer-margin-m;

  @include mq('wide') {
    margin-bottom: $wienbot-footer-margin-l;
  }
}
