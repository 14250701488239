/**
  * .wm-carousel 1.0.0
  * Karrusell
  * Im Handbuch: /pattern-library/patterns/karrusell/
*/

.wm-carousel {
  & > * {
    @include mq(wide) {
      flex-basis: $wm-carousel-card-large;
    }
  }

  &[data-size="large"] > * {
    @include mq(xl) {
      flex-basis: $wm-carousel-card-xlarge;
    }

    @include mq(wide) {
      flex-basis: $wm-carousel-card-xxlarge;
    }
  }

  &[data-tablet-only] {
    margin-left: 0;
  }
}

.tns-controls {
  @include mq(content-full-slider) {
    overflow: visible;
    position: static;
  }
}

[data-controls] {
  &[data-controls="prev"] {
    left: -3rem;
  }

  &[data-controls="next"] {
    right: -3rem;
  }

  @include mq(content-full-slider) {
    opacity: 1;
  }
}
