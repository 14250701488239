/*
 Import the additional styles from wm-design prototype for large displays.
 This is (more or less) a copy of the file $project-dir/src/wm-design/assets/scss/main-large.scss
*/

@import "../wm-design/assets/scss/abstracts/functions.scss";
@import "../wm-design/assets/scss/abstracts/colors.scss";
@import "../wm-design/assets/scss/abstracts/vars";
@import "../wm-design/assets/scss/abstracts/mixins.scss";
@import "../wm-design/assets/scss/vendor/mq";

/*
 In our Liferay build we cannot use the glob, as we would have to modify the 
 build for this and override a Liferay theme task or do some copy and replace 
 magic in a before hook.

 So we replaced this line with the explicit paths of the files.
*/
//@import "../wm-design/**/media/*large.scss";
@import "../wm-design/_includes/components/stage/media/_stage_large.scss";
@import "../wm-design/_includes/components/section/media/_section_large.scss";
@import "../wm-design/_includes/components/carousel/media/_carousel_large.scss";
@import "../wm-design/_includes/components/site/header/media/_site-header_large.scss";
@import "../wm-design/_includes/components/site/content/media/_site-content_large.scss";
@import "../wm-design/_includes/components/site/search/media/_site-search_large.scss";
@import "../wm-design/_includes/components/breaking-news/media/_breaking-news_large.scss";
@import "../wm-design/_includes/components/modal/media/_modal_large.scss";
@import "../wm-design/_includes/components/chatbot/media/_chatbot_large.scss";
@import "../wm-design/_includes/components/row/media/_row_large.scss";
@import "../wm-design/_includes/components/card/media/_card_large.scss";
@import "../wm-design/_includes/components/progress/media/_progress_large.scss";
@import "../wm-design/_includes/components/quicklinks/media/_quicklinks_large.scss";
@import "../wm-design/_includes/components/button/media/_button_large.scss";
@import "../wm-design/_includes/components/lists/media/_list_large.scss";
@import "../wm-design/_includes/components/nav/inpage/media/_nav-inpage_large.scss";
@import "../wm-design/_includes/components/nav/main/media/_nav-main_large.scss";