/**
  * .wm-breaking-news-wrapper und wm-breaking-news 1.0.0
  * Inhalt ein- und ausklappen
  * Im Handbuch: /pattern-library/patterns/breaking-news/
*/

.wm-breaking-news-wrapper {
  &--static {
    .wm-jsm .wm-site-content--in-page-nav & {
      @include mq(xl) {
        top: ($wm-spacing-xlarge - 1rem) * -1;
      }
    }
  }
}

.wm-breaking-news {
  &__button {
    top: 50%;
    transform: translateY(-50%);
  }

  @include mq(xl) {
    padding-top: 3.4rem;
    padding-bottom: 3.4rem;
  }
}
